@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  width: 100%;
  touch-action: manipulation;
}

.overflow-x-scroll::-webkit-scrollbar {
  display: none;
}

.overflow-y-scroll::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

p {
  margin: 0;
  line-height: 1.25;
  font-size: 16px;
  white-space: pre-line;
}

a {
  text-decoration: none;
}

div {
  box-sizing: border-box;
}

